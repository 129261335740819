@import 'src/utils/utils';

.setting-form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &:not(:first-child) {
    margin-top: 20px;

    @media(min-width: $md) {
      margin-top: 30px;
    }
  }

  @media(min-width: $md) {
    gap: 30px;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    border: 0;

    &:last-child {

      .setting-form__sub-fields:last-child {
        border-bottom: unset;
      }
    }
  }

  &__legend {
    @include Text-18-reg;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    color: $t-40;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-bottom: auto;
    flex-shrink: 0;
  }

  &__toggle {
    width: 24px;
    height: 24px;
    margin: 0;
    margin-bottom: auto;
    padding: 0;
    color: $Main-1;
    border: 0;
    background: 0;
    flex-shrink: 0;
    transition: color 0.3s;
    cursor: pointer;

    &:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
      }
    }

    svg {
      width: 24px;
      height: 24px;
      stroke: currentcolor;
    }
  }

  &__all {
    flex-shrink: 0;
    cursor: pointer;

    &:hover {
      @media(min-width: $lg) {

        input {

          &:checked {
    
            & + span {
              background-image: url('../assets/images/icon-bell-active--hover.svg');
            }
          }

          & + span {
            background-image: url('../assets/images/icon-bell--hover.svg');
          }
        }
      }
    }

    input {

      &:checked {

        & + span {
          background-image: url('../assets/images/icon-bell-active.svg');
        }
      }

      & + span {
        display: block;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: transparent;
        background-image: url('../assets/images/icon-bell.svg');
      }
    }

    span {
      display: block;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: transparent;
      background-image: url('../assets/images/icon-bell.svg');
    }
  }

  &__sub-fields {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    border: 0;
    border-bottom: 1px solid $Main-4;

    legend {
      @include Text-14-reg;
      display: flex;
      width: 100%;
      align-items: center;
      margin: 0;
      padding-top: 10px;
      padding-right: 0;
      padding-bottom: 10px;
      padding-left: 0;
      gap: 10px;

      @media(min-width: $md) {
        padding-top: 15px;
        padding-bottom: 15px;
        gap: 20px;
      }

      span {
        flex-grow: 1;
      }
    }

    .setting-form__item {
      position: relative;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 22px;
      border-bottom: unset;

      &:before {
        position: absolute;
        top: 10px;
        left: 0;
        width: 12px;
        height: 1px;
        margin: auto;
        border-top: 1px dashed $Main-6;
        content: '';
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: calc(100% + 10px);
        margin: auto;
        border-left: 1px dashed $Main-6;
        content: '';
      }

      &:first-child {
  
        &:after {
          top: -10px;
          height: calc(100% + 20px);
        }
      }

      &:last-child {
  
        &:after {
          height: 10px;
        }
      }
    }

    &--active {

      .setting-form {

        &__list {
          transition:
            visibility 0.2s ease-out 0.3s,
            opacity 0.2s ease-out 0.3s,
            max-height 0.3s ease-out;
          visibility: visible;
          opacity: 1;
        }

        &__toggle {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__sub-header {
    display: flex;
    max-width: 600px;
    align-items: center;
    margin-right: auto;
    flex-grow: 1;
    gap: 10px;
  }

  &__list {
    position: relative;
    display: flex;
    max-height: 0;
    flex-direction: column;
    margin: 0;
    padding: 0;
    padding-left: 12px;
    gap: 10px;
    list-style: none;
    transition:
      visibility 0.2s ease-in,
      opacity 0.2s ease-in,
      max-height 0.3s ease-in 0.2s;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;

    @media(min-width: $md) {
      gap: 15px;
    }
  }

  &__item {
    @include zero;
    @include Text-14-reg;
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: $Main-1;
    border-bottom: 1px solid $Main-4;
    text-decoration: none;
    gap: 10px;

    @media(min-width: $md) {
      padding-top: 15px;
      padding-bottom: 15px;
      gap: 20px;
    }

    &:first-child {
      margin-top: 5px;
    }

    &:last-child {
      margin-bottom: 10px;
      border-bottom: unset;
    }

    span {
      max-width: 560px;
      margin-right: auto;
      flex-grow: 1;
    }

    label {
      flex-shrink: 0;
      cursor: pointer;

      &:hover {
        @media(min-width: $lg) {

          input {

            &:checked {
      
              & + span {
                background-image: url('../assets/images/icon-bell-active--hover.svg');
              }
            }

            & + span {
              background-image: url('../assets/images/icon-bell--hover.svg');
            }
          }
        }
      }
    }

    input {

      &:checked {

        & + span {
          background-image: url('../assets/images/icon-bell-active.svg');
        }
      }

      & + span {
        display: block;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: transparent;
        background-image: url('../assets/images/icon-bell.svg');
      }
    }
  }
}